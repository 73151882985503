import axios from "axios";

export default class ExecutiveBulkMailInfoAPIClient {
    constructor(serviceUrl, axiosInstance) {
        this.serviceUrl = serviceUrl;
        this.axiosInstance = axiosInstance;
    }

    getBulkMailInfo(status,productCategory) {
        const url = `${this.serviceUrl}/bulkmail/getBulkmailByStatus?status=${status}&productCategory=${productCategory}`;
        return this.axiosInstance.get(url)
            .then(response => {
                return Promise.resolve(response.data);
            }).catch(() => {
                return Promise.reject()
            })

    }
}
