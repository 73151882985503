import axios from "axios";

export default class GetHealthAPIClient {

    constructor(serviceUrl, axiosInstance) {
        this.serviceUrl = serviceUrl;
        this.axiosInstance = axiosInstance
    }

    getHealth() {
        const url = `${this.serviceUrl}/healthcheck/email`;
        return this.axiosInstance.get(url)
            .then(response => {
                return Promise.resolve(response.data.status);
            }).catch((error) => {
                return Promise.reject(error)
            });
    }
}