import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useEffect, useState } from "react";

export function useAuthHelper(apiConfig, setToken) {
    const { instance, inProgress, accounts } = useMsal();

    const request = {
        ...apiConfig,
        account: accounts[0]
    };

    useEffect(() => {
        if (accounts.length > 0) {

            console.log(`Msal Interaction Status: ${inProgress}`);
            //InteractionStatus.None occurs when MSAL has finished logging in or getting a token.
            if (inProgress === InteractionStatus.None) {
                const getToken = async () => {
                    const response = await instance.acquireTokenSilent(request);
                    console.log("Token acquired!");
                    setToken(response.accessToken);
                }
                const getTokenRedirect = async () => {
                    const response = await instance.acquireTokenRedirect(request);
                    console.log("Token acquired via redirect");
                    setToken(response.accessToken);
                }

                getToken().catch((error) => {
                    console.log(`Failed to acquire Auth token silently=> ${error}`);
                    if (error instanceof InteractionRequiredAuthError) {
                        console.log("Attempting to acquire token via redirect");
                        getTokenRedirect().catch((e) => {
                            console.log(`Failed to acquire Auth token => ${e}`);
                        });
                    }

                }).finally(()=>{
                    console.log("Token is ready!")
                    // setTokenLoaded(true)
                });
            }
        }
        //Trigger the effect when the msal instance, account, or inProgress status changes to get a token after the login is finished.
    }, [instance, accounts,inProgress]);
}