import axios from "axios";

export default class FeedbackFormClient {
    constructor(serviceUrl, axiosInstance) {
        this.serviceUrl = serviceUrl;
        this.axiosInstance = axiosInstance;
    }
    submit(feedback) {
        const url = `${this.serviceUrl}/feedback`;
        return this.axiosInstance
            .post(url, feedback)
            .then(response => {
                return Promise.resolve(response.data);
            })
            .catch(() => {
                return Promise.reject();
            });
    }
}