import axios from "axios";
import createConfig from "../../config.js";

export default class ViewAllAdvisoriesAPIClient {

    constructor(serviceUrl, axiosInstance) {
        this.serviceUrl = serviceUrl;
        this.axiosInstance = axiosInstance;
    }

    getAllAdvisories() {
        const config = createConfig();
        const url = `${this.serviceUrl}/advisory/listAllByDays/${config.advisoryDaysToRetrieve}`;
        return this.axiosInstance.get(url)
            .then(response => {
                return Promise.resolve(this.processResponse(response.data, false));
            }).catch((error) => {
                return Promise.reject(error)
            });
    }

    processResponse(response, paged) {
        if(paged==true){
          const data =  Object.assign([], response, {content: response.content.map(advisoryFromServer => {
              advisoryFromServer.advisoryForm.situationBackground.submittedTime = new Date(
                  advisoryFromServer.advisoryForm.situationBackground.submittedTime + "Z"
              );
              advisoryFromServer.advisoryForm.submittedTime = new Date(
                  advisoryFromServer.advisoryForm.submittedTime + "Z"
              );
              return advisoryFromServer;
            })})
            return data;
        } else {
          return response.map(advisoryFromServer => {
              advisoryFromServer.advisoryForm.situationBackground.submittedTime = new Date(
                  advisoryFromServer.advisoryForm.situationBackground.submittedTime + "Z"
              );
              advisoryFromServer.advisoryForm.submittedTime = new Date(
                  advisoryFromServer.advisoryForm.submittedTime + "Z"
              );
              return advisoryFromServer;
          }).sort(function (advisory1, advisory2) {
              return advisory2.advisoryForm.submittedTime - advisory1.advisoryForm.submittedTime;
          });
        }
      }

      getPageOfAdvisories(page=0, size = 25, sortBy="id", sortOrder = "desc") {
        //console.log(`Retrieving Advisories -> Page=${page}, Size=${size}, Sort=${sortBy}, SortOrder=${sortOrder}`)
        const url = `${this.serviceUrl}/advisory/page?page=${page}&size=${size}&sort=${sortBy},${sortOrder}`
          return this.axiosInstance.get(url)
            .then(response => {
                return Promise.resolve(this.processResponse(response.data, true));
            }).catch((error) => {
                return Promise.reject(error)
            });
    }
}