export default function constructConfig() {
  var hostName = window.location.hostname;

  const validationconfig = {
    inputFields: {
      product: {
        label: "Product",
        maxLength: 254,
      },
      situationbackground: {
        label: "Situation Background",
        maxLength: 2000,
      },
      rootCause: {
        label: "Root Cause",
        maxLength: 2000,
      },
      usersImpacted: {
        label: "Users Impacted",
        maxLength: 2000,
      },
      impactDetails: {
        label: "Impact Details",
        maxLength: 2000,
      },
      updateSituationBackground: {
        label: "Update Situation/Background",
        maxLength: 2000,
      },
      updateBusinessImpact: {
        label: "Update Business Impact Details",
        maxLength: 2000,
      }
    },
  };

  //use session storage because memory storage doesn't support the redirect flow: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
  const entraConfigDev = {
    auth: {
      //Application (client) ID
      clientId: "bf7a5c44-72c1-4e90-9e24-8321249768cc",
      //Directory (tenant) ID
      authority: "https://login.microsoftonline.com/c990bb7a-51f4-439b-bd36-9c07fb1041c0",
      redirectUri: window.location.origin
      //redirectUri is set to the page that MSAL is running on. Use relative root.
      //redirectUri: '/',
    },
    cache: {
      temporaryCacheLocation: "sessionStorage",
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
      secureCookies: true,
    }
  }
  
  const apiConfigDev ={
    resourceUri: "https://api-dev.int.vehicle.ford.com/fam",
    // If the app is getting a token for itself, the scope should be the clientId + the scope (e.g. .default)
    scopes:['bf7a5c44-72c1-4e90-9e24-8321249768cc/.default']
  }

  const entraConfigQa = {
    auth:{
      clientId: "1a10ff73-db38-492c-87ca-fb1235eac53d",
      authority: "https://login.microsoftonline.com/c990bb7a-51f4-439b-bd36-9c07fb1041c0",
      redirectUri:  window.location.origin,
    },
    cache:{
      temporaryCacheLocation: "sessionStorage",
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
      secureCookies: true,
    }
  }

  const apiConfigQa ={
    resourceUri: "https://api-qat.int.vehicle.ford.com/fam",
    scopes:['1a10ff73-db38-492c-87ca-fb1235eac53d/.default']
  }

  const entraConfigProd = {
    auth:{
      clientId: "b4dfa272-ecda-4208-bed5-f6eecccfe620",
      authority: "https://login.microsoftonline.com/c990bb7a-51f4-439b-bd36-9c07fb1041c0",
      redirectUri: window.location.origin,
    },
    cache:{
      temporaryCacheLocation: "sessionStorage",
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
      secureCookies: true,
    }
  }

  const apiConfigProd ={
    resourceUri: "https://api.vehicle.ford.com/fam",
    scopes:['b4dfa272-ecda-4208-bed5-f6eecccfe620/.default']
  }


  if (hostName.includes("advisory.sre") ||
      hostName.includes("advisory-prod") ||
      hostName.includes("advisory.prod")) {
    return {
      baseUrl: "https://advisory.prod.ford.com",
      baseApiUrl: "https://api.vehicle.ford.com/fam/api",
      baseApiUrlWithoutApiPath: "https://api.vehicle.ford.com/fam",
      matomoShouldBeDisabled: true, //disabled now
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29,
      entraConfig: entraConfigProd,
      apiConfig: apiConfigProd
    };
  } else if (hostName.includes("advisory.qa") ||
      hostName.includes("advisory-qa")) {
    return {
      baseUrl: "https://advisory.qa.ford.com",
      baseApiUrl: "https://api-qat.int.vehicle.ford.com/fam/api",
      baseApiUrlWithoutApiPath: "https://api-qat.int.vehicle.ford.com/fam",
      matomoShouldBeDisabled: true,
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29,
      entraConfig: entraConfigQa,
      apiConfig: apiConfigQa
    }
  } else if (hostName.includes("advisory.dev") ||
      hostName.includes("advisory-dev")) {
    return {
      baseUrl:
          "https://advisory.dev.ford.com",
      baseApiUrl: "https://api-dev.int.vehicle.ford.com/fam/api",
      baseApiUrlWithoutApiPath: "https://api-dev.int.vehicle.ford.com/fam",
      matomoShouldBeDisabled: true,
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29,
      entraConfig: entraConfigDev,
      apiConfig: apiConfigDev
    };
  } else if (hostName.includes("localhost")) {
    return {
      baseUrl: "http://localhost:3000",
      baseApiUrl: "http://localhost:8080/api",
      baseApiUrlWithoutApiPath: "http://localhost:8080",
      matomoShouldBeDisabled: true,
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29,
      entraConfig: entraConfigDev,
      apiConfig: apiConfigDev
    };
  }
}

 

export const loginRequest = {
  scopes: ['User.Read'],
};

