import { Route, Switch, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import CreateAdvisoryAPIClient from "./components/AdvisoryForms/CreateAdvisory/CreateAdvisoryAPIClient";
import UpdateAdvisoryAPIClient from "./components/AdvisoryForms/UpdateAdvisory/UpdateAdvisoryAPIClient";
import { CreateAdvisory } from "./components/AdvisoryForms/CreateAdvisory/CreateAdvisory";
import createConfig from "./config";
import { AdvisoryForm } from "./AdvisoryForm.js";
import { UpdateAdvisory } from "./components/AdvisoryForms/UpdateAdvisory/UpdateAdvisory";
import BulkMailInfoAPIClient from "./BulkMailInfoAPIClient";
import { ViewAllAdvisories } from "./components/ViewAllAdvisories/ViewAllAdvisories";
import ViewAllAdvisoriesAPIClient from "./components/ViewAllAdvisories/ViewAllAdvisoriesAPIClient";
import { AdvisorySuccessConfirmation } from "./components/Confirmation/AdvisorySuccessConfirmation";
import { AdvisoryFailureConfirmation } from "./components/Confirmation/AdvisoryFailureConfirmation";
import { SiteFeedbackForm } from './components/FeedbackForm/SiteFeedbackForm'
import GetHealthAPIClient from "./components/GetHealthAPIClient";
import FeedbackFormClient from "./components/FeedbackForm/FeedbackFormClient";
import EmailFeedbackForm from "./components/FeedbackForm/EmailFeedbackForm";
import { FeedbackFailureConfirmation } from "./components/Confirmation/FeedbackFailureConfirmation";
import { FeedbackSuccessConfirmation } from "./components/Confirmation/FeedbackSuccessConfirmation";
import { createMuiTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import ExecutiveBulkMailInfoAPIClient from "./ExecutiveBulkMailInfoAPIClient";
import Footer from "./components/Footer";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useAuthHelper } from "./MSALAuthHelper.js";
import axios from "axios";
import { Header } from "./components/Header";


export default function App() {
    const config = createConfig();
    const { instance, inProgress, accounts } = useMsal();
    const [token, setToken] = useState("");
    useAuthHelper(config.apiConfig, setToken);
    const axiosInstance = axios.create({ headers: { "Authorization": `Bearer ${token}` } });

    const [cdsid, setCdsid] = useState("notFound")
    const [createFormData, setCreateFormData] = useState(new AdvisoryForm())

    const formLabelsTheme = createMuiTheme({
        overrides: {
            MuiFormLabel: {
                asterisk: {
                    color: '#db3131'
                }
            },
            MuiFormHelperText: {
                root: {
                    color: '#db3131',
                    textAlign: 'right'
                }
            }
        }
    })

    useEffect(() => {
        console.log("Token changed....")
        if (inProgress === InteractionStatus.None) {
            instance.setActiveAccount(accounts[0]);
            if (!instance.getActiveAccount()) {
                console.warn("No active user!")
            } else if (instance.getActiveAccount()) {
                console.info(`Login successful! Pulling cdsid from msal activeAccount: ${instance.getActiveAccount().username} and setting token. `);
                if ((cdsid === "notFound")) {
                    setCdsid(instance.getActiveAccount().username.split('@')[0]);
                }
            }
        }
    }, [inProgress, token])


    let location = useLocation();
    const { trackPageView, pushInstruction } = useMatomo()
    useEffect(() => {
        trackPageView({ href: location.pathname })
    }, [location]);

    useEffect(() => {
        createFormData.cdsid = cdsid;
        createFormData.contactInfo = [cdsid + "@ford.com"];
        if (cdsid !== 'notFound') {
            pushInstruction('setUserId', cdsid)
        }
    }, [cdsid])

    //if MSAL is injected as a prop, the MSAL Provider wrapper would go here: <MsalProvider instance={msalInstance}> </MsalProvider>
    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Switch>
                    <Route path="/updateAdvisory/:updateId">
                        <MuiThemeProvider theme={formLabelsTheme}>

                            <UpdateAdvisory
                                cdsid={cdsid}
                                bulkMailInfoAPIClient={
                                    new BulkMailInfoAPIClient(config.baseApiUrl, axiosInstance)
                                }
                                executiveBulkMailAPIClient={
                                    new ExecutiveBulkMailInfoAPIClient(config.baseApiUrl, axiosInstance)
                                }
                                updateAdvisoryClient={
                                    new UpdateAdvisoryAPIClient(config.baseApiUrl, axiosInstance)
                                }
                            />
                        </MuiThemeProvider>

                    </Route>
                    <Route path="/createAdvisory">
                        <MuiThemeProvider theme={formLabelsTheme}>
                            <CreateAdvisory
                                cdsid={cdsid}
                                formData={createFormData}
                                bulkMailInfoAPIClient={
                                    new BulkMailInfoAPIClient(config.baseApiUrl, axiosInstance)
                                }
                                submitFormClient={
                                    new CreateAdvisoryAPIClient(config.baseApiUrl, axiosInstance)
                                }
                            />
                        </MuiThemeProvider>
                    </Route>
                    <Route path="/successConfirmation">
                        <AdvisorySuccessConfirmation
                            cdsid={cdsid}
                        />
                    </Route>
                    <Route path="/failureConfirmation">
                        <AdvisoryFailureConfirmation
                            cdsid={cdsid}
                        />
                    </Route>
                    <Route path="/feedbackSuccessConfirmation">
                        <FeedbackSuccessConfirmation
                            cdsid={cdsid}
                        />
                    </Route>
                    <Route path="/feedbackFailureConfirmation">
                        <FeedbackFailureConfirmation
                            cdsid={cdsid}
                        />
                    </Route>
                    <Route path="/feedbackForm">
                        <SiteFeedbackForm
                            cdsid={cdsid}
                            submitFormClient={
                                new FeedbackFormClient(config.baseApiUrl, axiosInstance)
                            }
                        />
                    </Route>
                    <Route path="/emailFeedbackForm">
                        <EmailFeedbackForm cdsid={cdsid}
                            submitFormClient={
                                new FeedbackFormClient(config.baseApiUrl, axiosInstance)
                            } />
                    </Route>
                    <Route path="/">
                        {inProgress === InteractionStatus.None && token !== ""  ? (
                            <div>
                                <ViewAllAdvisories
                                    cdsid={cdsid}
                                    viewAllAdvisoriesAPIClient={
                                        new ViewAllAdvisoriesAPIClient(config.baseApiUrl, axiosInstance)
                                    }
                                    getHealthApiClient={new GetHealthAPIClient(config.baseApiUrlWithoutApiPath, axiosInstance)}
                                />
                            </div>
                        ) : (
                            <>
                                <Header cdsid={cdsid} />
                                <div className="loadingAdvisories">
                                    <div className="loadingAdvisoriesText">Fetching advisories ...</div>
                                    <img
                                        src={require("./Graphics/FAM-Loading-Screen-Preloader.gif")}
                                        alt="Loading"
                                    />
                                </div>
                            </>)}
                    </Route>
                </Switch>
                <Footer />
            </MsalAuthenticationTemplate>
        </>
    );
}
