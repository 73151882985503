import axios from "axios";

export default class BulkMailInfoAPIClient {
    constructor(serviceUrl, axiosInstance) {
        this.serviceUrl = serviceUrl;
        this.axiosInstance = axiosInstance;
    }
    getBulkMailInfo(regions,priority) {
        const url = `${this.serviceUrl}/bulkmail/getByPriorityAndRegions?priority=${priority}&regions=${regions.join(',')}`;
        return this.axiosInstance.get(url)
            .then(response => {
                return Promise.resolve(response.data);
            }).catch(() => {
                return Promise.reject()
            })

    }
}
