import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {ensureHTTPS} from "./HTTPSentinel";
import {createInstance, MatomoProvider} from '@datapunt/matomo-tracker-react';
import createConfig from "./config";
import {BrowserRouter} from "react-router-dom";
import Container from "@material-ui/core/Container";
import { PublicClientApplication, Configuration} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const instance = createInstance({
    urlBase: 'https://matomo.sre.ford.com',
    siteId: 5,
    disabled: createConfig().matomoShouldBeDisabled, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST'
    }
})

// MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders (i.e. index.js is a good place for this).
const pca = new PublicClientApplication(createConfig(window.location.hostname).entraConfig);

//MSAL instance can either wrap the protected component(i.e. Wrap App to require Auth throughout the whole application) or be passed as a prop to a component (i.e. passed as a prop to App).
document.title = "Ford Advisory Management";
ensureHTTPS();
ReactDOM.render(
    <React.StrictMode>
        <MatomoProvider value={instance}>
            <BrowserRouter>
                <div className="fam-app">
                    <Container maxWidth="lg">
                        <MsalProvider instance={pca}>
                            <App/>
                        </MsalProvider>
                    </Container>
                </div>
            </BrowserRouter>
        </MatomoProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
