import axios from "axios";

export default class UpdateAdvisoryAPIClient {
    constructor(serviceUrl, axiosInstance) {
        this.serviceUrl = serviceUrl;
        this.axiosInstance = axiosInstance;
    }

    get(id) {
        const convertStringToDate = dateString => {
            if (dateString) {
                return new Date(dateString + "Z");
            }
            return null;
        };

        const convertUpdate = update => {
            update.submittedTime = convertStringToDate(update.submittedTime);
            return update;
        };
        const url = `${this.serviceUrl}/advisory/${id}`;
        return this.axiosInstance.get(url)
            .then(response => {
                const advisoryForm = response.data.advisoryForm;
                advisoryForm.startDate = convertStringToDate(
                    advisoryForm.startDate
                );
                advisoryForm.endDate = convertStringToDate(advisoryForm.endDate);
                advisoryForm.situationBackgroundUpdates = advisoryForm.situationBackgroundUpdates.map(
                    update => convertUpdate(update)
                );
                advisoryForm.situationBackground = convertUpdate(
                    advisoryForm.situationBackground
                );
                advisoryForm.businessImpactDetails.biStartDate = convertStringToDate(
                    advisoryForm.businessImpactDetails.biStartDate
                );
                advisoryForm.businessImpactDetails.biEndDate = convertStringToDate(
                    advisoryForm.businessImpactDetails.biEndDate
                );
                advisoryForm.businessImpactDetails.impactDetails = convertUpdate(
                    advisoryForm.businessImpactDetails.impactDetails
                );
                advisoryForm.businessImpactDetailsUpdates = advisoryForm.businessImpactDetailsUpdates.map(
                    update => convertUpdate(update)
                );
                advisoryForm.submittedTime = convertStringToDate(
                    advisoryForm.submittedTime
                );

                response.data.advisoryForm = advisoryForm;
                return Promise.resolve(response.data);
            })
            .catch(() => {
                return Promise.reject("fails");
            });
    }

    submit(formData, id) {
        const url = `${this.serviceUrl}/advisory/${id}`;
        return this.axiosInstance
            .post(url, formData)
            .then(response => {
                return Promise.resolve(response.data);
            })
            .catch(() => {
                return Promise.reject();
            });
    }
}
