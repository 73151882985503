import axios from "axios";
import { set } from "date-fns";

export default class CreateAdvisoryAPIClient {
  constructor(serviceUrl, axiosInstance) {
    this.serviceUrl = serviceUrl;
    this.axiosInstance = axiosInstance;
  }
  submit(formData) {
    const url = `${this.serviceUrl}/advisory`;
    return this.axiosInstance
        .post(url, formData)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}
